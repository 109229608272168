import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import cn from "classnames";
import Image from "next/image";
import {Button, Icon} from "@shopify/polaris";
import Link from "next/link";

import {
  AppExtensionMinor
} from '@shopify/polaris-icons';

const Department = ({imgSrc, header, text, link, reverse = false, external, alt}) => {
  return (
    <div className={cn(styles["department"], {[styles["department--reverse"]]: reverse})}>
      <div className={styles["department__img-wrapper"]}>
        <Image
          quality={100}
          src={imgSrc}
          width={720}
          height={560}
          alt={alt}
        />
      </div>
      <div className={styles["department__content"]}>
        <div className={styles["department__content-container"]}>

          <h4 className={styles["department__header"]}>{header}</h4>
          <div className={styles["department__info"]}>
            <p className={styles["department__text"]}>{text}</p>
            <Link href={link.href}>
              <a className={cn(styles["department__link"], "department__link")} target={external ? "_blank" : "_self"}>
                <Icon source={AppExtensionMinor}/>
                <span className={styles["department__link-text"]}>{link.text}</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
    ;
};


Department.propTypes = {
  imgSrc: PropTypes.string,
  header: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string
  }),
  reverse: PropTypes.bool,
  external: PropTypes.bool,
  alt: PropTypes.string
}

export default Department;