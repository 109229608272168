import styles from "./styles.module.scss";
import Department from "../../../blocks/Department";
import useTranslation from "next-translate/useTranslation";
import {HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";

const Departments = () => {
  const {t} = useTranslation(HOME_NAMESPACE);

  const academy = {
    more: {
      text: t("departments.academy.more.text"),
    },
    header: t("departments.academy.header"),
    text: t("departments.academy.text"),
    link: {
      text: t("departments.academy.link"),
      href: "https://devit.academy/"
    },
    "imgSrc": "/home/academy.png",
    "alt": "Visit Academy center image"
  }

  const training = {
    more: {
      text: t("departments.training.more.text"),
    },
    header: t("departments.training.header"),
    text: t("departments.training.text"),
    link: {
      text: t("departments.training.link"),
      href: "/training-center"
    },
    "imgSrc": "/home/training.png",
    "alt": "Visit training center image"
  }

  return (
    <div className={styles["departments"]}>
      <div className={styles["departments__dep-wrapper"]}>
        <Department
          imgSrc={training.imgSrc}
          link={{
            href: training.link.href,
            text: training.link.text
          }}
          header={training.header}
          text={training.text}
          alt={training.alt}
          reverse
        />
      </div>
      <div className={styles["departments__dep-wrapper"]}>
        <Department
          imgSrc={academy.imgSrc}
          link={{
            href: academy.link.href,
            text: academy.link.text
          }}
          header={academy.header}
          text={academy.text}
          alt={academy.alt}
          external
        />
      </div>
    </div>
  );
};

export default Departments;